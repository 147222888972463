import { SemanticColors } from "../utils/colors";

export default function Loader(props: {
  size?: number;
  color?: SemanticColors;
  fancy?: boolean;
}) {
  const size = props.size ?? 20;

  if (!props.fancy) {
    return (
      <svg
        height={size}
        width={size}
        viewBox="25 25 50 50"
        className="animate-spin"
        style={{ animationDuration: "2s" }}
      >
        <circle
          className="stroke-text"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="2.5"
          strokeDasharray={Math.PI * 2 * size * 0.3}
          strokeMiterlimit="10"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    );
  }

  return (
    <>
      <svg
        viewBox="0 0 100 100"
        height={size || 200}
        width={size || 200}
        className="border-txt m-auto animate-spin-slow"
      >
        <circle
          cx="50"
          cy="50"
          r="20"
          className="stroke-primary"
          strokeWidth={1}
          fill="none"
        />
        <circle
          cx="53"
          cy="52"
          r="22"
          className="stroke-secondary"
          strokeWidth={1}
          fill="none"
        />
        <circle
          cx="49"
          cy="52"
          r="22"
          className="stroke-textInverted"
          strokeWidth={1}
          fill="none"
        />
        <circle
          cx="51"
          cy="48"
          r="21"
          className="stroke-text"
          strokeWidth={1}
          fill="none"
        />
      </svg>
    </>
  );
}
