import clsx from "clsx";
import { ComponentProps, createContext, useContext } from "react";
import { Text as RNText } from "react-native";

export const TextClassNameContext = createContext<string | null>(null);

type CustomTextProps = {
  contextClassName?: string;
};

export const textColorClasses = {
  black: "text-white",
  white: "text-black",
  green: "text-black",
  purple: "text-black",
  blue: "text-black",
  red: "text-black",
  gray: "text-black",
  primary: "text-textPrimary",
  secondary: "text-textSecondary",
  text: "text-textInverted",
  textInverted: "text-text",
  bg1: "text-text",
  highlight: "text-highlight",
} as const;

const baseTextClasses = "text-base text-text";
export default function Text({
  className,
  // color,
  contextClassName,
  ...props
}: ComponentProps<typeof RNText> & CustomTextProps) {
  const parentClassName = useContext(TextClassNameContext);

  if (contextClassName) {
    return (
      <TextClassNameContext.Provider value={contextClassName}>
        <RNText
          className={clsx(
            baseTextClasses,
            parentClassName,
            contextClassName,
            className,
          )}
          {...props}
        />
      </TextClassNameContext.Provider>
    );
  }

  return (
    <RNText
      className={clsx(baseTextClasses, parentClassName, className)}
      {...props}
    />
  );
}
