import { IconNames } from "./types";

import iconData from "./icons.json";
import { Pressable, View } from "../atoms/styled";
// import { Path, Svg } from "../atoms/styled";
import { SemanticColors } from "../utils/colors";
import clsx from "clsx";
import { Path, Svg } from "react-native-svg";
import { cssInterop } from "nativewind";
import { useContext } from "react";
import { TextClassNameContext } from "../atoms/Text";

// const iconColorClasses = {
//   black: "text-black",
//   white: "text-white",
//   green: "text-green",
//   purple: "text-purple",
//   blue: "text-blue",
//   red: "text-red",
//   gray: "text-gray",
//   text: "text-text",
//   primary: "text-primary",
//   secondary: "text-secondary",
//   textInverted: "text-textInverted",
//   bg: "text-bg",
// } as const;

cssInterop(Svg, {
  className: {
    target: "style",
    nativeStyleToProp: { width: true, height: true },
  },
});

// cssInterop(Path, {
//   className: {
//     target: "style",
//     nativeStyleToProp: { fill: true, stroke: true },
//   },
// });

export const iconColorClasses = {
  black: "text-black",
  white: "text-white",
  green: "text-green-400",
  purple: "text-purple-400",
  blue: "text-blue-400",
  red: "text-red-400",
  gray: "text-gray-400",
  primary: "text-primary",
  secondary: "text-secondary",
  text: "text-text",
  textInverted: "text-textInverted",
  bg1: "text-bg1",
  highlight: "text-highlight",
} as const;

export default function Icon({
  icon,
  size,
  containerClassName,
  color,
  className,
  onPress,
  ...props
}: React.ComponentPropsWithoutRef<typeof Svg> & {
  icon: IconNames;
  size?: number;
  containerClassName?: string;
  color?: SemanticColors;
}) {
  const parentClassName = useContext(TextClassNameContext);

  const Child = (
    <Svg
      width={size || "18"}
      height={size || "18"}
      viewBox="0 0 18 18"
      className={clsx(
        "transition-colors",
        "text-text",
        parentClassName,
        color && iconColorClasses[color],
        className,
      )}
      {...props}
    >
      <Path
        d={iconData[icon].d as string}
        fill={props.fill || "currentColor"}
        stroke={
          iconData[icon].hasStroke ? props.fill || "currentColor" : "none"
        }
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </Svg>
  );

  if (onPress)
    return (
      <Pressable onPress={onPress} className={containerClassName}>
        {Child}
      </Pressable>
    );

  return <View className={containerClassName}>{Child}</View>;
}
