import clsx from "clsx";

import { ModalProps } from "react-native-modal";
import { motion, AnimatePresence } from "framer-motion";

export default function Modal(props: Partial<ModalProps>) {
  return (
    <AnimatePresence>
      {props.isVisible && (
        <>
          <motion.div
            className={clsx(
              "fixed bottom-0 left-0 right-0 top-0 z-[100] flex flex-col items-center justify-center bg-black/40 p-8",
            )}
            onClick={props.onBackdropPress}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              onClick={(e) => e.stopPropagation()}
              initial={{ y: "10vh", opacity: 0 }}
              animate={{ y: "0", opacity: 1 }}
              exit={{ y: "10vh", opacity: 0 }}
              transition={{ type: "spring", damping: 25, stiffness: 500 }}
            >
              {props.children}
            </motion.div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}
