// export const colorCodes = {
//   black: ["#000", "#000"],
//   white: ["#fff", "#E5E7EB"],
//   green: ["#86EFAC", "#49de80"],
//   purple: ["#D8B4FE", "#bf84fc"],
//   blue: ["#BFDBFE", "#92c5fd"],
//   red: ["#FEB4B4", "#f77171"],
//   gray: ["#e5e7eb", "#d1d5db"],
// } as const;

// export type ViewColors = keyof typeof colorCodes;
export type SemanticColors =
  | "primary"
  | "secondary"
  | "text"
  | "textInverted"
  | "bg1"
  | "highlight";

export const bgBorderClasses = {
  black: "bg-gray-900 text-white border-black",
  white: "bg-white text-black border-gray-200",
  green: "bg-green-300 border-green-400",
  purple: "bg-purple-300 border-purple-400",
  blue: "bg-blue-200 border-blue-300",
  red: "bg-red-300 border-red-400",
  gray: "bg-gray-200 border-gray-300",
  primary: "bg-primary border-borderPrimary",
  secondary: "bg-secondary border-borderSecondary",
  text: "bg-text border-text",
  textInverted: "bg-textInverted border-textInverted",
  bg1: "bg-bg1 border-bg2",
  highlight: "bg-bg2 border-highlight",
} as const;

export const activeClasses = {
  primary: "active:bg-primary/50",
  secondary: "active:bg-secondary/50",
  text: "active:bg-text/50",
  textInverted: "active:bg-textInverted/50",
  bg1: "active:bg-bg2/50",
  highlight: "active:bg-highlight/50",
} as const;

export const solidBorderClasses = {
  primary: "bg-borderPrimary shadow-bgPrimary",
  secondary: "bg-borderSecondary shadow-bgSecondary",
  bg1: "bg-bg2 shadow-bg2",
  // less good
  // text: "bg-text",
  // textInverted: "bg-textInverted",
  // highlight: "border-highlight",
} as const;

export type SolidBorderColors = keyof typeof solidBorderClasses;

export const hoverClasses = {
  black: "hover:bg-slate-800 group-hover:bg-slate-800",
  white: "hover:bg-gray-100 group-hover:bg-gray-100",
  green: "hover:bg-green-200 group-hover:bg-green-200",
  purple: "hover:bg-purple-200 group-hover:bg-purple-200",
  blue: "hover:bg-blue-100 group-hover:bg-blue-100",
  red: "hover:bg-red-200 group-hover:bg-red-200",
  gray: "hover:bg-gray-100 group-hover:bg-gray-100",
  primary: "hover:bg-borderPrimary group-hover:bg-borderPrimary",
  secondary: "hover:bg-borderSecondary group-hover:bg-borderSecondary",
  text: "hover:bg-text group-hover:bg-text",
  textInverted: "hover:bg-textInverted group-hover:bg-textInverted",
  bg1: "hover:bg-bg2 group-hover:bg-bg2",
  highlight: "hover:bg-highlight group-hover:bg-highlight",
} as const;
