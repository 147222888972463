import clsx from "clsx";
import { Pressable, View } from "react-native";
import Loader from "./Loader";
import { useEffect, useState } from "react";
import Icon from "../icons";

import {
  GestureResponderEvent,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from "react-native";
import { IconNames } from "../icons/types";
import {
  SemanticColors,
  activeClasses,
  bgBorderClasses,
} from "../utils/colors";
import Text, { TextClassNameContext, textColorClasses } from "./Text";
import Modal from "./Modal";

const textVariantClasses = {
  xs: "text-xs",
  s: "text-sm font-normal",
  m: "text-base",
  l: "text-lg",
} as const;

const iconSizeMap = {
  xs: 12,
  s: 16,
  m: 20,
  l: 24,
} as const;

export default function Button({
  onPress,
  onClick,
  children,
  variant = "m",
  color = "primary",
  isLoading = false,
  className,
  width,
  disabled = false,
  error,
  text,
  icon,
  style,
  testID,
}: {
  onPress?: () => void;
  onClick?: (event: GestureResponderEvent) => void;
  isLoading?: boolean;
  error?: string;
  variant?: "xs" | "s" | "m" | "l";
  color?: SemanticColors;
  width?: number;
  disabled?: boolean;
  children?: React.ReactNode;
  text?: string | null;
  icon?: IconNames;
  style?: StyleProp<ViewStyle>;
  className?: string;
  testID?: string;
}) {
  const [hideError, setHideError] = useState(false);
  useEffect(() => {
    if (error) {
      setHideError(false);
    }
  }, [error]);

  return (
    <>
      <Pressable
        accessibilityRole="button"
        disabled={disabled}
        onPress={(e) => {
          onPress?.();
          onClick?.(e);
        }}
        className={clsx(
          className,
          "group flex flex-row items-center justify-center transition-all duration-300",
          bgBorderClasses[color],
          activeClasses[color],
          {
            "border-2": variant === "m" || variant === "l",
          },
          {
            "cursor-not-allowed opacity-30": disabled,
          },
        )}
        style={[variantStyles[variant], { width, gap: 4 }, style]}
        testID={testID}
      >
        <TextClassNameContext.Provider value={textColorClasses[color]}>
          {/* <View
        // border={variant === "m" || variant === "l"}
        // style={[styles.background, variantStyles[variant]]}
        className={clsx(
          "pointer-events-none transition-colors",
          bgBorderClasses[color],
          {
            "border-2": variant === "m" || variant === "l",
          },
        )}
      /> */}
          {text && (
            <Text
              className={clsx(
                "relative flex flex-row items-center justify-center whitespace-nowrap text-center font-medium tracking-wider",

                textVariantClasses[variant],
              )}
            >
              {!isLoading ? text : <Loader />}
            </Text>
          )}
          {icon && <Icon icon={icon} size={iconSizeMap[variant]} />}
          {children}
        </TextClassNameContext.Provider>
      </Pressable>
      <Modal
        name="buttonErrorModal"
        isVisible={!hideError && !!error}
        onBackdropPress={() => setHideError(true)}
      >
        <View className="rounded-lg border-highlight bg-bg1 p-4">
          <Text>{error}</Text>
        </View>
      </Modal>
    </>
  );
}

const variantStyles = StyleSheet.create({
  xs: {
    height: 22,
    borderRadius: 2,
    paddingHorizontal: 4,
    paddingVertical: 2,
  },
  s: {
    height: 28,
    borderRadius: 4,
    paddingHorizontal: 8,
  },
  m: {
    height: 40,
    borderRadius: 4,
    paddingHorizontal: 16,
  },
  l: {
    height: 48,
    borderRadius: 8,
    paddingHorizontal: 22,
  },
});
